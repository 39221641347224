window.onload = function () {
  const soundOnBtn = document.getElementById('js-sound-on');
  const soundOffBtn = document.getElementById('js-sound-off');
  const audio = document.getElementById('audioPlayer');
  audio.autoplay = true;

  var playPromise = document.getElementById('audioPlayer').play();

  // In browsers that don’t yet support this functionality,
  // playPromise won’t be defined.
  if (playPromise !== undefined) {
    playPromise.then(function() {
      // Automatic playback started!
      // console.log('success');
    }).catch(function(error) {
      // Automatic playback failed.
      // Show a UI element to let the user manually start playback.
      // console.log('fail');
    });
  }

  soundOnBtn.addEventListener('click', (e) => {
    e.preventDefault();
    toggleSoundIcon();
    audio.play();
  });
  soundOffBtn.addEventListener('click', (e) => {
    e.preventDefault();
    toggleSoundIcon();
    audio.pause();
    // audio.currentTime = 0;
  });

  setInterval(() => {
    replayAudioIfOver();
  }, 1000);

  function toggleSoundIcon() {
    soundOnBtn.classList.toggle('visuallyhidden');
    soundOffBtn.classList.toggle('visuallyhidden');
  }

  function replayAudioIfOver() {
    const audioDuration = audio.duration;
    let audioCurrTime = audio.currentTime;

    if(audioCurrTime == audioDuration) {
      setTimeout(() => {
        audio.play();
      }, 500);
    }
  }

  /////////////////////////////////////////////////////////////////
  const servicesListLeft = [
    'TERAPIA TRANSPESSOAL',
    'NATURAL COACHING',
    'MEDITAÇÃO',
    'SOUND HEALING',
    'AROMOTERAPIA'
  ];
  const servicesListRight = [
    'HIPNOTERAPIA',
    'PROGRAMAS ESPELHO ',
    'MEDITAÇÃO PARA CRIANÇAS ',
    'AROMOTERAPIA',
    'REIKI'
  ];
  
  const serviceLeft = document.getElementById('js-service-left');
  const serviceRight = document.getElementById('js-service-right');
  
  serviceLeft.innerHTML = servicesListLeft[0];
  serviceRight.innerHTML = servicesListRight[0];

  let counter = 1;
  const arrayLength = servicesListLeft.length - 1;

  function changeService() {
    serviceLeft.innerHTML = servicesListLeft[counter];
    serviceRight.innerHTML = servicesListRight[counter];
    counter === arrayLength ? counter = 0 : counter++;
  }

  setInterval(() => {
    changeService();
  }, 2000);
}
